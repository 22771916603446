import * as React from 'react';
import { Helmet } from 'react-helmet-async';

import { graphql } from 'gatsby';

import LayoutFrame from '../components/layout/LayoutFrame';

interface IPrimaryTemplateProps {
  data: {
    prismic: {
      _allDocuments: {
        edges: [
          {
            node: {
              _meta: {
                uid: string;
                type: string;
              };
              title: string;
            };
          },
        ];
      };
    };
  };
}

const PrimaryTemplate: React.FunctionComponent<IPrimaryTemplateProps> = ({ data }) => {
  // Required check for no data being returned
  const doc = data.prismic._allDocuments.edges.slice(0, 1).pop();
  if (!doc) {
    return null;
  }

  const { uid } = doc.node._meta;
  const title = doc.node.title;

  return (
    <LayoutFrame currPath={`/${uid}/`}>
      <Helmet>
        <title>{title}</title>
      </Helmet>
    </LayoutFrame>
  );
};

export default PrimaryTemplate;

// IMPORTANT: Include all content types that you expect to use this template
// This is including much more than we want just to get the base project to build
export const query = graphql`
  query PrismicTemplateQuery($id: String) {
    prismic {
      _allDocuments(id: $id) {
        edges {
          node {
            _meta {
              uid
              id
              type
            }
          }
        }
      }
    }
  }
`;
